<template>
  <div class="box flex-center">
    <div class="login-box">
      <div class="title">账号/手机登录</div>
      <div class="account ipt-box mt-20">
        <i class="el-icon-user ipt-icon"></i>
        <input v-model="formData.account" placeholder="请输入用户名" class="ipt ipt-reset" type="text" />
      </div>
      <div class="password ipt-box mt-20">
        <i class="el-icon-lock ipt-icon"></i>
        <input v-model="formData.password" placeholder="请输入密码" class="ipt ipt-reset" type="password" />
      </div>
      <div class="submit mt-40"  v-throttle="([loginSubmit,800])">登录</div>
    </div>
  </div>
</template>
<script>
import { loginApi } from "@/api/apiList";
import { throttle } from "@/util/throttle";
export default {
  mixins: [throttle],
  data(){
    return {
      formData:{
        account: "",
        password: "",
      }
    }
  },
  methods: {
    async loginSubmit() {
      if(!this.checkForm())return
      let loginRes = await loginApi(this.formData);
      if (loginRes.data.code == 0) {
        this.$message.success("登录成功");
        window.localStorage.setItem("Access-Token", loginRes.data.data.token);
        this.$router.push('/about')
      } else if (loginRes.data.code == 800) {
        this.$message({
          message: loginRes.data.msg,
          type: "error",
        });
      } else {
        this.$message({
          message: "网络错误",
          type: "error",
        });
      }
    },
    checkForm(){
      let status=true;
      if(!this.formData.account){
        status=false;
        this.$message.error('用户名不能为空')
        return
      }
      if(!this.formData.password){
        status=false;
        this.$message.error('密码不能为空')
        return
      }
      return status
    }
  },
};
</script>
<style lang="scss" scoped>
.box {
  width: 100vw;
  height: 100vh;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 240px 0;
  background: url("https://wanchuyun-zihua.oss-cn-guangzhou.aliyuncs.com/manghe/dda697de4df8544576ccabfe2bf19329f9683dd3.png")
    no-repeat;
  background-size: cover;
}
.login-box {
  width: 375px;
  background-color: rgba(242, 242, 242, 0.7);
  box-sizing: border-box;
  padding: 0 20px;
  padding-bottom: 20px;
}
.title {
  padding: 20px 0;
  font-size: 24px;
  text-align: center;
}
.ipt-box {
  width: 100%;
  align-items: center;
  position: relative;
  .ipt-icon {
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
  }
  .ipt {
    width: 100%;
    box-sizing: border-box;
    padding: 20px 10px 20px 34px;
    height: 30px;
    flex-shrink: 0;
    border: 1px solid #ccc; /* 设置边框颜色 */
    border-radius: 5px; /* 设置边框圆角 */
    outline: none; /* 去除默认的轮廓线 */
    transition: border-color 0.3s, background-color 0.3s; /* 添加过渡效果 */
  }
  input {
  }

  /* 当input元素获得焦点时的样式 */
  input:focus {
  }
  .ipt:focus {
    border-color: #2196f3; /* 选中时的边框颜色 */
    background-color: #e0e0e0; /* 选中时的背景颜色 */
  }
}
.submit {
  background-color: #1e9fff;
  padding: 10px 0;
  color: #fff;
  text-align: center;
  cursor: pointer;
  transition: opacity 0.5s ease; /* 平滑过渡效果 */
}
.submit:hover {
  opacity: 0.7;
}
</style>
