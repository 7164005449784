import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from '../views/LoginView.vue'
import AboutView from '../views/AboutView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView
  },
  // {
  //   path: '/home',
  //   name: 'home',
  //   component: () => import('../views/HomeView.vue')
  // },
  {
    path: '/about',
    component: AboutView,
    redirect: '/data',
    children: [{
      path: '/data',//数据展示
      name: 'data',
      component: () => import('../views/data/DataChart.vue')
    }, {
      path: '/userList',//用户列表
      name: 'userList',
      component: () => import('../views/user/UserList.vue')
    }, {
      path: '/userDetail',//用户详情
      name: 'userDetail',
      meta: {
        superPath: '/userList'
      },
      component: () => import('../views/user/UserDetail.vue')
    }, {
      path: '/memberList',//会员等级列表
      name: 'memberList',
      component: () => import('../views/user/MemberList.vue')
    },{
      path: '/adminList',//管理员列表
      name: 'adminList',
      component: () => import('../views/admin/AdminList.vue')
    }, {
      path: '/worksList',//作品列表
      name: 'worksList',
      component: () => import('../views/works/WorksList.vue')
    }, {
      path: '/worksDetail',//作品详情
      name: 'worksDetail',
      meta: {
        superPath: '/worksList'
      },
      component: () => import('../views/works/WorksDetail.vue')
    }, {
      path: '/worksAuditing',//作品审核列表
      name: 'worksAuditing',
      component: () => import('../views/works/WorksAuditing.vue')
    }, {
      path: '/worksLabel',//标签列表
      name: 'worksLabel',
      component: () => import('../views/works/WorksLabel.vue')
    }, {
      path: '/labelAuditing',//审核标签
      name: 'labelAuditing',
      component: () => import('../views/works/LabelAuditing.vue')
    }, {
      path: '/adList',//广告列表
      name: 'adList',
      component: () => import('../views/works/AdList.vue')
    }, {
      path: '/adDetail',//广告详情
      name: 'adDetail',
      component: () => import('../views/works/AdDetail.vue'),
      meta: {
        superPath: '/adList'
      }
    }, {
      path: '/questionList',//问题列表
      name: 'questionList',
      component: () => import('../views/question/QuestionList.vue')
    }, {
      path: '/questionDetail',//问题详情
      name: 'questionDetail',
      component: () => import('../views/question/QuestionDetail.vue'),
      meta: {
        superPath: '/questionList'
      }
    }, {
      path: '/questionAuditing',//问题审核
      name: 'questionAuditing',
      component: () => import('../views/question/QuestionAuditing.vue')
    }, {
      path: '/answerAuditing',//答案审核
      name: 'answerAuditing',
      component: () => import('../views/question/AnswerAuditing.vue')
    }, {
      path: '/answerDetail',//答案详情
      name: 'answerDetail',
      component: () => import('../views/question/AnswerDetail.vue'),
      meta: {
        superPath: '/answerAuditing'
      }
    }, {
      path: '/complainList',//投诉管理列表
      name: 'complainList',
      component: () => import('../views/complain/ComplainList.vue')
    }, {
      path: '/complainDetail',//投诉详情
      name: 'complainDetail',
      component: () => import('../views/complain/ComplainDetail.vue'),
      meta: {
        superPath: '/complainList'
      }
    },
    {
      path: '/complainType',//投诉类型管理
      name: 'complainType',
      component: () => import('../views/complain/ComplainType.vue')
    },
    {
      path: '/withdrawalAsk',//提现申请列表
      name: 'withdrawalAsk',
      component: () => import('../views/property/WithdrawalAsk.vue')
    }, {
      path: '/rewardList',//奖励记录列表
      name: 'rewardList',
      component: () => import('../views/property/RewardList.vue')
    }, {
      path: '/likeList',//赞数记录列表
      name: 'likeList',
      component: () => import('../views/property/LikeList.vue')
    },  {
      path: '/withdrawalList',//提现记录列表
      name: 'withdrawalList',
      component: () => import('../views/property/WithdrawalList.vue')
    }, {
      path: '/payment',//微信支付配置
      name: 'payment',
      component: () => import('../views/system/PaymentView.vue')
    }, {
      path: '/banner',//轮播设置
      name: 'banner',
      component: () => import('../views/system/BannerView.vue')
    }, {
      path: '/bannerDetail',//轮播详情
      name: 'bannerDetail',
      component: () => import('../views/system/BannerDetail.vue'),
      meta: {
        superPath: '/banner'
      }
    }, {
      path: '/program',//小程序信息配置
      name: 'program',
      component: () => import('../views/system/ProgramView.vue')
    }, {
      path: '/setting',//账号设置
      name: 'setting',
      component: () => import('../views/system/SettingView.vue')
    }
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
export default router
