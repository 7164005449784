<template>
  <div class="about">
    <el-container style="height: 500px; border: 1px solid #eee">
      <el-aside
        :width="!collapse ? '200px' : '64px'"
        style="background-color: rgb(238, 241, 246)"
      >
        <el-menu
          :default-active="routeIndex"
          class="el-menu-left"
          background-color="#20222A"
          text-color="rgba(255, 255, 255, .7)"
          active-text-color="#16b777"
          :collapse="collapse"
          :unique-opened="true"
          router
        >
          <div class="logo-box flex-center">
            <!-- <img class="logo-img" src="../assets/logo.png" alt="" srcset="" /> -->
            <span v-if="!collapse">学而赚</span>
            <span v-else>学</span>
          </div>
          <el-submenu v-for="nav in menu" :key="nav.title" :index="nav.title">
            <template slot="title">
              <i :class="nav.icon"></i>
              <span>{{ nav.title }}</span>
            </template>
            <template v-for="navItem in nav.list">
              <el-menu-item
                v-if="!navItem.pid"
                :key="navItem.title"
                :index="navItem.path"
                >{{ navItem.title }}</el-menu-item
              >
            </template>
          </el-submenu>
        </el-menu>
      </el-aside>
      <el-container>
        <el-header>
          <div class="nav-head flex-between">
            <div class="nav-left flex">
              <i
                @click="changeCollapse"
                v-if="collapse"
                class="el-icon-s-unfold cur-pointer"
              ></i>
              <i
                @click="changeCollapse"
                v-if="!collapse"
                class="el-icon-s-fold cur-pointer"
              ></i>
              <i @click="refrech" class="el-icon-refresh-right cur-pointer"></i>
            </div>
            <div class="nav-right flex">
              <i
                v-if="!screenStatus"
                @click="fullScreen"
                class="el-icon-full-screen cur-pointer"
              ></i
              ><i
                v-else
                @click="exitFullscreen"
                class="el-icon-crop cur-pointer"
              ></i
              ><el-dropdown @command="handleCommand">
                <span class="user-phone cur-pointer">{{
                  userInfo.a_name
                }}</span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :command="0">修改密码</el-dropdown-item>
                  <el-dropdown-item :command="1">退出</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
          <div class="nav-bread flex">
            <el-breadcrumb separator="/">
              <el-breadcrumb-item>{{ getFather.title }}</el-breadcrumb-item>
              <el-breadcrumb-item
                :to="{ path: fatherRoute.path }"
                v-if="fatherRoute"
                >{{ fatherRoute.title }}</el-breadcrumb-item
              >
              <el-breadcrumb-item>{{ activeNav.title }}</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
        </el-header>
        <el-main>
          <router-view ref="router"></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
import { getMenuByData } from "../config/menuList";
import { getUserInfoApi } from "@/api/apiList";
export default {
  data() {
    return {
      menu: getMenuByData(),
      screenStatus: false,
      collapse: false,
      screenWidth: 0,
      userInfo: {
        a_name: "用户名",
      },
    };
  },
  created() {
    this.initSreen();
  },
  async mounted() {
    await this.getUserInfo();
  },
  watch: {
    $route(a, b) {
      if (b.path == "/worksAuditing" && a.path == "/worksDetail") {
        a.meta.superPath = "/worksAuditing";
      } else if (b.path == "/worksList" && a.path == "/worksDetail") {
        a.meta.superPath = "/worksList";
      }

      if (b.path == "/questionList" && a.path == "/questionDetail") {
        a.meta.superPath = "/questionList";
      } else if (b.path == "/questionAuditing" && a.path == "/questionDetail") {
        a.meta.superPath = "/questionAuditing";
      }
    },
  },
  computed: {
    routeIndex() {
      if (this.$route.meta.superPath) {
        return this.$route.meta.superPath;
      } else {
        return this.$route.path;
      }
    },
    activeRoute() {
      return this.$route.path;
    },
    fatherRoute() {
      if (this.$route.meta.superPath) {
        let a = this.menu.find((item) =>
          item.list.find((rou) => rou.path == this.$route.meta.superPath)
        ).list;
        return a.find((rou) => rou.path == this.$route.meta.superPath);
      }
      if (!this.activeNav.pid) {
        return;
      }
      let list = this.menu.find((nav) =>
        nav.list.find((item) => item.path == this.activeRoute)
      ).list;
      let nav = list.find((item) => item.id == this.activeNav.pid);
      return nav;
    },
    activeNav() {
      let nav;
      this.menu.forEach((item) => {
        item.list.forEach((navItem) => {
          if (navItem.path == this.activeRoute) {
            nav = navItem;
          }
        });
      });
      return nav;
    },
    getFather() {
      return this.menu.find((nav) =>
        nav.list.find((item) => item.path == this.activeRoute)
      );
    },
  },
  methods: {
    async getUserInfo() {
      let infoRes = await getUserInfoApi();
      if (infoRes.data.code == 0) {
        this.userInfo = infoRes.data.data;
      }
      return infoRes;
    },
    toPage(path) {
      this.$router.push({ path });
    },
    //获取屏幕宽度并绑定
    initSreen() {
      this.screenWidth = document.body.clientWidth;
      if (this.screenWidth <= 900) {
        this.collapse = true;
      }
      // window.onresize = () => {
      //   console.log("高度");
      //   //屏幕尺寸变化
      //   return (() => {
      //     this.screenWidth = document.body.clientWidth;
      //   })();
      // };
    },
    //
    handleCommand(index) {
      switch (index) {
        case 0:
          this.toPage("/setting");
          break;
        case 1:
          this.logout();
          break;
      }
    },
    //退出登录
    logout() {
      localStorage.removeItem("Access-Token");
      this.$router.replace({
        path: "/",
      });
    },
    //刷新页面
    refrech() {
      location.reload();
    },
    //全屏展示
    fullScreen() {
      let element = document.documentElement;
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.mozRequestFullscreen) {
        element.mozRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
      this.screenStatus = true;
    },
    //关闭全屏
    exitFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
      this.screenStatus = false;
    },
    //侧边栏收起
    changeCollapse() {
      this.collapse = !this.collapse;
      if(this.$refs.router.myChart)
      this.$nextTick(()=>{
        this.$refs.router.chartResize()
      })
    },
  },
};
</script>
<style lang="scss" scoped>
::-webkit-scrollbar {
  display: none;
}
:deep(.el-container) {
  height: 100vh !important;
  border: none !important;
}
:deep(.el-menu-left) {
  height: 100vh;
}
:deep(.el-submenu .el-menu-item) {
  width: auto;
  min-width: 0 !important;
}
:deep(.el-aside) {
  max-width: 220px !important;
}
:deep(.el-header) {
  padding: 0 !important;
  height: auto !important;
}
:deep(.el-main) {
  padding: 15px !important;
  background-color: #f2f2f2 !important;
}
:deep(.nav-head [class^="el-icon-"]) {
  font-size: 20px !important;
}
.logo-box {
  font-size: 20px;
  // font-weight: bold;
  gap: 0 10px;
  padding: 10px 0;
  color: rgba(255, 255, 255, 0.7);
  .logo-img {
    width: 45px;
    height: 45px;
  }
}
.nav-head {
  padding: 0 30px;
  align-items: center;
  height: 52px;
  border-bottom: none;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  .nav-left {
    gap: 0 30px;
  }
  .nav-right {
    gap: 0 30px;
    align-items: center;
  }
}
.nav-bread {
  align-items: center;
  height: 50px;
  box-sizing: border-box;
  border-top: 1px solid #f6f6f6;
  padding: 0 16px;
}
</style>
