import api from './axios'
export function apiRequset({
    method,
    url,
    params
}) {
    return api({
        method,
        url,
        data: params,
        headers: {
            'Authorization': localStorage.getItem('Access-Token'),
            'Content-Type': 'application/x-www-form-urlencoded'
        },
    })
}
export function apiPost({
    url,
    params
}) {
    return api({
        method:'POST',
        url,
        data: params,
        headers: {
            'Authorization': localStorage.getItem('Access-Token'),
            'Content-Type': 'application/x-www-form-urlencoded'
        },
    })
}
export function apiGet({
    url
}) {
    return api({
        method:'GET',
        url,
        responseType: 'blob',
        headers: {
            'Authorization': localStorage.getItem('Access-Token'),
            'Content-Type': 'application/x-www-form-urlencoded'
        },
    })
}
export function apiUpload({
    url,file
}) {
    return api({
        method:'POST',
        url,
        data: file,
        headers: {
            'Authorization': localStorage.getItem('Access-Token'),
            'Content-Type': 'multipart/form-data'
        },
    })
}