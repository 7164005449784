import axios from "axios";
import { Message } from 'element-ui'
import router from "@/router"

let baseURL = process.env.NODE_ENV === 'development' ? 'https://word.sxruanjian.cn/' : 'https://word.sxruanjian.cn/'

var api = axios.create({
    baseURL: baseURL, // api的base_url
    timeout: 5000 // 请求超时时间
});
api.defaults.timeout = 2500;

api.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
api.interceptors.response.use((response) => {
    // 对响应数据做点什么
    if (response.data.code == 401) {
        Message.error('登录过期')
        setTimeout(() => {
            localStorage.removeItem("Access-Token");
            if(router.app._route.name!='login'){
                router.replace({
                    path: "/",
                });
            }
        }, 400)
    }
    return response;
}, function () {
    // 对响应错误做点什么
    Message.error('网络错误')
    return {
        data:{
            code:404
        }
    }
    // return Promise.reject(error);
});

export default api