import { apiPost,apiGet, apiUpload } from './api'

/**
 * 登录接口
 * @param {*} params 
 * account 账号
 * password 密码
 * @returns 
 */
export let loginApi = function (params) {
    return apiPost({
        url: '/admin/login',
        params
    })
}
/**
 * 上传图片接口
 * @param {*} params 
 * file 文件
 * @returns 
 */
export let uploadImgApi = function (file) {
    return apiUpload({
        url: '/admin/upload',
        file
    })
}


/**
 * 获取统计图数据
 * @param {*} params 
 * type 默认值类型 1 日 2 周 3 月
 * @returns 
 */
 export let getChartDataApi = function (params) {
    return apiPost({
        url: '/admin/rechargeChartData',
        params
    })
}

/**
 * 获取模块统计数据
 * @param {*} params 
 * 
 * @returns 
 */
 export let getindexStartDataApi = function (params) {
    return apiPost({
        url: '/admin/indexStatData',
        params
    })
}

/**
 * 更新系统设置接口
 * @param {*} params 
 * type 6 微信支付7平台投诉邮箱8首页作品展示数量9非会员作品显示数量10佣金比例
 * @returns 
 */
export let sysSetingApi = function (params) {
    return apiPost({
        url: '/admin/syssetting',
        params
    })
}

/**
 * 获取系统设置接口
 * @param {*} params 
 * type 6 微信支付7平台投诉邮箱8首页作品展示数量9非会员作品显示数量10佣金比例
 * @returns 
 */
export let getSetingApi = function (params) {
    return apiPost({
        url: '/admin/getsetting',
        params
    })
}

/**
 * 获取会员等级列表接口
 * @param {*} params 
 * page 第几页
 * limit 每页条数
 * @returns 
 */
export let getMemberListApi = function (params = { page: '1', limit: '20' }) {
    return apiPost({
        url: '/admin/userLevelList',
        params
    })
}
/**
 * 获取会员等级列表接口
 * @param {*} params 
 * name 等级标题
 * icon 会员logo
 * price 会员价格
 * like_count 赠送点赞数
 * @returns 
 */
export let createMemberApi = function (params) {
    return apiPost({
        url: '/admin/addUserLevel',
        params
    })
}

/**
 * 编辑会员等级列表接口
 * @param {*} params 
 * level_id 对应的等级id
 * name 等级标题
 * icon 会员logo
 * price 会员价格
 * like_count 赠送点赞数
 * @returns 
 */
export let editMemberApi = function (params) {
    return apiPost({
        url: '/admin/editUserLevel',
        params
    })
}

/**
 * 删除会员等级列表接口
 * @param {*} params 
 * level_id 对应的等级id
 * @returns 
 */
export let deleteMemberApi = (params) => {
    return apiPost({
        url: '/admin/deleteUserLevel',
        params
    })
}

/**
 * 修改密码
 * @param {*} params 
 * oldpwd  旧密码
 * changepwd  新密码
 * confirmpwd  确认密码
 * @returns 
 */
export let setPasswordApi = (params) => {
    return apiPost({
        url: '/admin/updatepwd',
        params
    })
}

/**
 * 获取小程序用户列表
 * @param {*} params 
 * page 页数
 * limit 每页几条
 * mobile 手机号
 * u_nickname 昵称
 * start_time , end_time 注册时间
 * @returns 
 */
export let getUserListApi = (params = { page: '1', limit: '20' }) => {
    return apiPost({
        url: '/admin/memberlist',
        params
    })
}

/**
 * 用户停用/开启
 * @param {*} params 
 * u_id userId
 * status 1启用2禁用
 * @returns 
 */
export let setUserStatusApi = (params) => {
    return apiPost({
        url: '/admin/setUstatus',
        params
    })
}

/**
 * 获取作品列表
 * @param {*} params 
 * page 
 * limit 
 * status 1待审核2审核通过3审核拒绝0全部
 * name 作品名称
 * label_name 标签名称
 * @returns 
 */
export let getWorksListApi = (params = { page: '1', limit: '20', status: '0' }) => {
    return apiPost({
        url: '/admin/worksList',
        params
    })
}

/**
 * 获取作品详情
 * @param {*} params 
 * id 作品id
 * @returns 
 */
export let getWorksDetailApi = (params) => {
    return apiPost({
        url: '/admin/worksShow',
        params
    })
}

/**
 * 审核作品
 * @param {*} params 
 * id 作品id
 * status 1待审核2审核通过3审核拒绝
 * @returns 
 */
 export let auditWorksApi = (params) => {
    return apiPost({
        url: '/admin/worksAuditing',
        params
    })
}

/**
 * 删除作品
 * @param {*} params 
 * id 作品id
 * @returns 
 */
 export let deleteWorksApi = (params) => {
    return apiPost({
        url: '/admin/worksDelete',
        params
    })
}

/**
 * 新增作品
 * @param {*} params 
 * u_id
 * is_original
 * text
 * voice
 * video
 * label_ids
 * name
 * @returns 
 */
export let createWorksApi = (params) => {
    return apiPost({
        url: '/admin/worksAdd',
        params
    })
}

/**
 * 修改作品
 * @param {*} params 
 * id 作品id
 * u_id
 * is_original
 * text
 * voice
 * video
 * label_ids
 * name
 * @returns 
 */
export let editWorksApi = (params) => {
    return apiPost({
        url: '/admin/worksEdit',
        params
    })
}

/**
 * 获取标签列表
 * @param {*} params 
 * page
 * limit
 * name
 * status 1待审核2审核通过3审核拒绝
 * @returns 
 */
 export let getLabelListApi = (params = { page: '1', limit: '20' }) => {
    return apiPost({
        url: '/admin/worksLabelList',
        params
    })
}


/**
 * 审核标签
 * @param {*} params 
 * id
 * status 1待审核2审核通过3审核拒绝
 * @returns 
 */
 export let auditingLabelApi = (params) => {
    return apiPost({
        url: '/admin/worksLabelAuditing',
        params
    })
}


/**
 * 新增标签
 * @param {*} params 
 * name
 * @returns 
 */
 export let createLabelApi = (params) => {
    return apiPost({
        url: '/admin/worksLabelAdd',
        params
    })
}


/**
 * 修改标签
 * @param {*} params 
 * id
 * name 
 */
 export let editLabelApi = (params) => {
    return apiPost({
        url: '/admin/worksLabelEdit',
        params
    })
}


/**
 * 删除标签
 * @param {*} params 
 * id
 * @returns 
 */
 export let deleteLabelApi = (params) => {
    return apiPost({
        url: '/admin/worksLabelDelete',
        params
    })
}

/**
 * 获取问题列表
 * @param {*} params 
 * page
 * limit
 * status 1待审核2审核通过3审核拒绝0全部
 * name
 * u_nickname
 * mobile
 * start_time
 * end_time
 * @returns 
 */
 export let getQuestionListApi = (params = { page: '1', limit: '20' }) => {
    return apiPost({
        url: '/admin/issueList',
        params
    })
}

/**
 * 获取问题详情
 * @param {*} params 
 * id
 * @returns 
 */
 export let getQuestionDetailApi = (params) => {
    return apiPost({
        url: '/admin/issueShow',
        params
    })
}

/**
 * 删除问题
 * @param {*} params 
 * id
 * @returns 
 */
 export let deleteQuestionApi = (params) => {
    return apiPost({
        url: '/admin/issueDelete',
        params
    })
}

/**
 * 审核问题
 * @param {*} params 
 * id 问题id
 * status 1待审核2审核通过3审核拒绝
 * @returns 
 */
 export let auditQuestionApi = (params) => {
    return apiPost({
        url: '/admin/issueAuditing',
        params
    })
}


/**
 * 获取答案列表
 * @param {*} params 
 * page
 * limit
 * name
 * status 1待审核2审核通过3审核拒绝0全部
 * u_nickname
 * mobile
 * start_time
 * end_time
 * @returns 
 */
 export let getAnswerListApi = (params = { page: '1', limit: '20' }) => {
    return apiPost({
        url: '/admin/answerList',
        params
    })
}

/**
 * 获取答案详情
 * @param {*} params 
 * id
 * @returns 
 */
 export let getAnswerDetailApi = (params) => {
    return apiPost({
        url: '/admin/answerShow',
        params
    })
}

/**
 * 审核答案
 * @param {*} params 
 * id
 * status 1待审核2审核通过3审核拒绝
 * @returns 
 */
 export let auditAnswerApi = (params) => {
    return apiPost({
        url: '/admin/answerAuditing',
        params
    })
}

/**
 * 获取投诉类型列表
 * @param {*} params 
 * page
 * limit
 * @returns 
 */
 export let getComplainTypeListApi = (params) => {
    return apiPost({
        url: '/admin/complainTypeList',
        params
    })
}

/**
 * 新增投诉类型
 * @param {*} params 
 * name
 * @returns 
 */
 export let addComplainTypeApi = (params) => {
    return apiPost({
        url: '/admin/complainTypeAdd',
        params
    })
}

/**
 * 删除投诉类型
 * @param {*} params 
 * id
 * @returns 
 */
 export let deleteComplainTypeApi = (params) => {
    return apiPost({
        url: '/admin/complainTypeDelete',
        params
    })
}

/**
 * 修改投诉类型
 * @param {*} params 
 * id
 * name
 * @returns 
 */
 export let editComplainTypeApi = (params) => {
    return apiPost({
        url: '/admin/complainTypeEdit',
        params
    })
}

/**
 * 获取投诉列表
 * @param {*} params 
 * page
 * limit
 * u_nickname
 * mobile
 * status 1已处理2未处理0全部
 * @returns 
 */
 export let getComplainListApi = (params = { page: '1', limit: '20' }) => {
    return apiPost({
        url: '/admin/complainList',
        params
    })
}

/**
 * 获取投诉详情
 * @param {*} params 
 * id
 * feedback 反馈
 * @returns 
 */
 export let getComplainDetailApi = (params = { page: '1', limit: '20' }) => {
    return apiPost({
        url: '/admin/complainShow',
        params
    })
}

/**
 * 反馈投诉
 * @param {*} params 
 * id
 * feedback 反馈
 * @returns 
 */
 export let editComplainApi = (params) => {
    return apiPost({
        url: '/admin/complainEdit',
        params
    })
}

/**
 * 获取轮播列表
 * @param {*} params 
 * page
 * limit
 * @returns 
 */
 export let getSliderListApi = (params = { page: '1', limit: '20' }) => {
    return apiPost({
        url: '/admin/sliderList',
        params
    })
}

/**
 * 添加轮播
 * @param {*} params 
 * ch_image 图片
 * ch_status 1启用0关闭
 * content 图文
 * @returns 
 */
 export let addSliderApi = (params) => {
    return apiPost({
        url: '/admin/sliderAdd',
        params
    })
}

/**
 * 编辑轮播
 * @param {*} params 
 * id
 * ch_image 图片
 * ch_status 1启用0关闭
 * content 图文
 * @returns 
 */
 export let editSliderApi = (params) => {
    return apiPost({
        url: '/admin/sliderEdit',
        params
    })
}

/**
 * 删除轮播
 * @param {*} params 
 * id
 * @returns 
 */
 export let removeSliderApi = (params) => {
    return apiPost({
        url: '/admin/sliderDelete',
        params
    })
}

/**
 * 获取轮播列详情
 * @param {*} params 
 * id
 * @returns 
 */
 export let getSliderDetailApi = (params = { page: '1', limit: '20' }) => {
    return apiPost({
        url: '/admin/sliderDetail',
        params
    })
}

/**
 * 修改轮播开启/关闭
 * @param {*} params 
 * id
 * status 1启用0禁用
 * @returns 
 */
 export let setSliderStatusApi = (params = { page: '1', limit: '20' }) => {
    return apiPost({
        url: '/admin/sliderStatus',
        params
    })
}

/**
 * 获取广告列表
 * @param {*} params 
 * page
 * limit
 * status 1待审核2审核通过3审核拒绝0全部
 * @returns 
 */
 export let getAdListApi = (params = { page: '1', limit: '20' }) => {
    return apiPost({
        url: '/admin/worksAdList',
        params
    })
}

/**
 * 审核广告
 * @param {*} params 
 * id
 * status 1待审核2审核通过3审核拒绝
 * @returns 
 */
 export let auditAdApi = (params) => {
    return apiPost({
        url: '/admin/worksAdAuditing',
        params
    })
}

/**
 * 获取广告详情
 * @param {*} params 
 * id
 * @returns 
 */
 export let getAdDetailApi = (params) => {
    return apiPost({
        url: '/admin/worksAdShow',
        params
    })
}

/**
 * 获取管理员列表
 * @param {*} params 
 * page
 * limit
 * @returns 
 */
 export let getAdminListApi = (params = { page: '1', limit: '20' }) => {
    return apiPost({
        url: '/admin/index',
        params
    })
}

/**
 * 编辑管理员
 * @param {*} params 
 * a_id
 * @returns 
 */
 export let editAdminApi = (params) => {
    return apiPost({
        url: '/admin/edit',
        params
    })
}

/**
 * 新增管理员
 * @param {*} params 
 * role_id
 * a_account
 * a_name
 * a_password
 * @returns 
 */
 export let addAdminApi = (params) => {
    return apiPost({
        url: '/admin/add',
        params
    })
}

/**
 * 删除管理员
 * @param {*} params 
 * a_id
 * @returns 
 */
 export let deleteAdminApi = (params) => {
    return apiPost({
        url: '/admin/admindelete',
        params
    })
}

/**
 * 管理员详情
 * @param {*} params 
 * @returns 
 */
 export let getUserInfoApi = (params) => {
    return apiPost({
        url: '/admin/adminShow',
        params
    })
}


/**
 * 禁用/开启管理员
 * @param {*} params 
 * a_id
 * a_status 1启用2禁用
 * @returns 
 */
 export let setAdminStatusApi = (params) => {
    return apiPost({
        url: '/admin/setStatus',
        params
    })
}


/**
 * 获取提现记录列表
 * @param {*} params 
 * page
 * limit
 * status 1待审核2审核通过3审核拒绝
 * @returns 
 */
 export let getWithdrawalList = (params = { page: '1', limit: '20' }) => {
    return apiPost({
        url: '/admin/userWithdrawalList',
        params
    })
}

/**
 * 审核提现记录
 * @param {*} params 
 * id
 * status 1待审核2审核通过3审核拒绝
 * @returns 
 */
 export let auditingWithdrawal = (params) => {
    return apiPost({
        url: '/admin/userWithdrawalAuditing',
        params
    })
}

/**
 * 对提现进行打款
 * @param {*} params 
 * id
 * is_payment 0未打款 1己打款
 * @returns 
 */
 export let paymentApi = (params) => {
    return apiPost({
        url: '/admin/payment',
        params
    })
}


/**
 * 获取奖励记录列表
 * @param {*} params 
 * page
 * limit
 * type 2答案加点赞数5点赞加点赞数6分销加点赞数0全部
 * classify 1赞数明细2赞数消耗
 * @returns 
 */
 export let getRewardList = (params = { page: '1', limit: '20' }) => {
    return apiPost({
        url: '/admin/userBuyLogList',
        params
    })
}




/**
 * 导出提现表
 * @param {*} params
 * status 1待审核2审核通过3审核拒绝0全部
 * mobile
 * u_nickname
 * is_payment 0全部 2未打款 1已打款
 * @returns 
 */
 export let exportTableApi = (params) => {
     let getUrl='';
     for (const key in params) {
         if(params[key]){
             if(!getUrl){
                 getUrl+=`?${key}=${params[key]}`
             }else{
                getUrl+=`&${key}=${params[key]}`
             }
         }
     }
    return apiGet({
        url: '/admin/userWithdrawalExport'+getUrl
    })
}








