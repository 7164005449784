export let menuList = [
    {
        path: '/data',//数据展示
        name: 'data',
        component: () => import('../views/data/DataChart.vue')
    }, {
        path: '/userList',//用户列表
        name: 'userList',
        component: () => import('../views/user/UserList.vue')
    }, {
        path: '/userDetail',//用户详情
        name: 'userDetail',
        meta: {
            superPath: '/userList'
        },
        component: () => import('../views/user/UserDetail.vue')
    }, {
        path: '/memberList',//会员等级列表
        name: 'memberList',
        component: () => import('../views/user/MemberList.vue')
    }, {
        path: '/worksList',//作品列表
        name: 'worksList',
        component: () => import('../views/works/WorksList.vue')
    }, {
        path: '/worksAuditing',//作品审核列表
        name: 'worksAuditing',
        component: () => import('../views/works/WorksAuditing.vue')
    }, {
        path: '/worksLabel',//标签列表
        name: 'worksLabel',
        component: () => import('../views/works/WorksLabel.vue')
    }, {
        path: '/questionList',//问题列表
        name: 'questionList',
        component: () => import('../views/question/QuestionList.vue')
    }, {
        path: '/questionAuditing',//问题审核
        name: 'questionAuditing',
        component: () => import('../views/question/QuestionAuditing.vue')
    }, {
        path: '/answerAuditing',//答案审核
        name: 'answerAuditing',
        component: () => import('../views/question/AnswerAuditing.vue')
    }, {
        path: '/complainList',//投诉管理列表
        name: 'complainList',
        component: () => import('../views/complain/ComplainList.vue')
    },
    {
        path: '/withdrawalAsk',//提现申请列表
        name: 'withdrawalAsk',
        component: () => import('../views/property/WithdrawalAsk.vue')
    }, {
        path: '/withdrawalList',//提现记录列表
        name: 'withdrawalList',
        component: () => import('../views/property/WithdrawalList.vue')
    }, {
        path: '/rewardList',//奖励记录列表
        name: 'rewardList',
        component: () => import('../views/property/RewardList.vue')
    }, {
        path: '/payment',//微信支付配置
        name: 'payment',
        component: () => import('../views/system/PaymentView.vue')
    }, {
        path: '/program',//小程序信息配置
        name: 'program',
        component: () => import('../views/system/ProgramView.vue')
    }
]

export let menuData = [{
    title: '数据统计',
    icon: 'el-icon-pie-chart',
    list: [
        {
            title: '统计报表',
            path: '/data'
        }
    ]
}, {
    title: '用户管理',
    icon: 'el-icon-user',
    list: [
        {
            title: '用户列表',
            path: '/userList',
            id: 1
        },
        {
            title: '用户详情',
            path: '/userDetail',
            pid: 1
        },
        {
            title: '会员设置',
            path: '/memberList'
        }
    ]
}, {
    title: '管理员',
    icon: 'el-icon-s-custom',
    list: [
        {
            title: '管理员列表',
            path: '/adminList',
            id: 1
        }
    ]
}, {
    title: '作品管理',
    icon: 'el-icon-document-copy',
    list: [
        {
            title: '作品列表',
            path: '/worksList',
            id: 1
        },
        {
            title: '作品信息',
            path: '/worksDetail',
            pid: 1
        },
        {
            title: '作品审核',
            path: '/worksAuditing'
        },
        {
            title: '标签列表',
            path: '/worksLabel'
        }, 
        {
            title: '标签审核',
            path: '/labelAuditing'
        },
        {
            title: '广告列表',
            path: '/adList',
            id: 2
        },
        {
            title: '广告详情',
            path: '/adDetail',
            pid: 2
        }
    ]
}, {
    title: '问题悬赏',
    icon: 'el-icon-document',
    list: [
        {
            title: '问题列表',
            path: '/questionList',
            id: 1
        },
        {
            title: '问题详情',
            path: '/questionDetail',
            pid: 1
        },
        {
            title: '问题审核',
            path: '/questionAuditing'
        },
        {
            title: '回答详情',
            path: '/answerDetail',
            pid: 2
        },
        {
            title: '回答审核',
            path: '/answerAuditing',
            id: 2
        }
    ]
}, {
    title: '投诉管理',
    icon: "el-icon-chat-line-square",
    list: [
        {
            title: '投诉列表',
            path: '/complainList',
            id: 1
        }, {
            title: '投诉详情',
            path: '/complainDetail',
            pid: 1
        }, {
            title: '投诉类型',
            path: '/complainType',
            id: 2
        }
    ]
}, {
    title: '财务管理',
    icon: 'el-icon-bank-card',
    list: [
        {
            title: '提现记录',
            path: '/withdrawalList'
        },
        {
            title: '提现申请',
            path: '/withdrawalAsk'
        },
        {
            title: '奖励记录',
            path: '/rewardList'
        },
        {
            title: '赞数记录',
            path: '/likeList'
        }
    ]
}, {
    title: '系统设置',
    icon: "el-icon-setting",
    list: [{
        title: '支付设置',
        path: '/payment'
    }, {
        title: '小程序设置',
        path: '/program'
    }, {
        title: '轮播设置',
        path: '/banner',
        id: 1
    }, {
        title: '轮播详情',
        path: '/bannerDetail',
        pid: 1
    }, {
        title: '账号设置',
        path: '/setting'
    }]
}]

//根据menu数据生成menu列表   data
export function getMenuByData() {
    let menu = JSON.parse(JSON.stringify(menuData));
    menu.forEach(nav => {
        nav.list.map(navItem => {
            return {
                ...navItem,
                name: navItem.path,
                // component:menuList.find(e=>e.path == navItem.path)
            }
        })
    });
    return menu
}